/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/rocket.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["000_glass"].geometry}
        material={
          materials["Cylinder material 1_0f1a1176-b807-4c68-81e9-0c70b2bc605e"]
        }
        position={[19.68, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["001_window"].geometry}
        material={
          materials["Tube material_be2f431d-8971-4807-b90d-f135eaf50fdb"]
        }
        position={[19.68, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["002_wings"].geometry}
        material={
          materials["Box material_b9b0e8a7-7f3a-485e-ace6-1973a3d26c25"]
        }
        position={[19.68, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["003_rocket_body_top"].geometry}
        material={
          materials[
            "Buffer Layer material 1_a0d9ab46-00c8-4271-9819-b1046a4331f4"
          ]
        }
        position={[19.68, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["004_engine"].geometry}
        material={
          materials[
            "Buffer Layer material 2_f828d099-b14c-4d9d-bfd4-77c32e395094"
          ]
        }
        position={[19.68, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["005_rocket_body"].geometry}
        material={
          materials["Cylinder material_e8fd07c5-fcb7-49a3-a5a2-ae149a78a1b8"]
        }
        position={[19.68, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["006_flame"].geometry}
        material={
          materials[
            "Buffer Layer material_dda3d5db-77f3-4488-b1ac-07bb3266da01"
          ]
        }
        position={[19.68, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload("/rocket.glb");